export default {
  siteTitle: 'Get bonus',
  blogerName: 'QUARTEL',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCNEyPuv7scW_YQxgGgFfhxA',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@quartelapostas333',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCXewqovR5gLBdloE0JbKXEA',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCkHZSKisRD4l8bFeSyONqfQ',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@quartelspaceman1890/shorts',
    },
    {
      name: 'kwai',
      url: 'https://kwai-video.com/u/@cenasdefavela/c7cwCil2',
    }
  ],
  projects: [
    {
      name: 'drip',
      url: 'https://dripcasino.life/cb1ba3582',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c414aafc5',
      gameTitle: 'Izzi Art (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c71d0e310',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c7f5fa5db',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c0a4d3b51',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/ce64e8ebe',
      gameTitle: 'Starda Queen (BGaming)', // имя игры изменено. копировать внимательно!
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c86222cbe',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>QUARTEL</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'QUARTEL',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
